import React from "react";
import Layout from "../components/layout";

export default () => (
  <Layout>
    <h1 className="_center">Mad City</h1>
    <div className="News">
      <h4>Latest News</h4>
      <ul className="News-posts">
        <li>
          <a
            href="/the-largest-valorant-production-to-date-and-how-cloud-computing-enabled-it/"
            title="The largest VALORANT production to date and how cloud computing enabled it"
          >
            The largest VALORANT production to date and how cloud computing
            enabled it
          </a>
        </li>
      </ul>
    </div>
  </Layout>
);
